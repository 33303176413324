import React, { useEffect, useState } from 'react'
import styles from './my-consults.module.css'
import { useDispatch, useSelector } from 'react-redux'
import { getTotalConsultsByUser, isEmpty, resumen } from '../../../utils/functions'
import { Link, useNavigate } from 'react-router-dom'
import { GetConsultsByUser } from '../../../store/actions'
import { Dispatch } from 'redux'

const MyConsultas = (): JSX.Element => {
    const { consults, user } = useSelector((state: any) => state)
    const dispatch: Dispatch<any> = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [pageSize, setPageSize] = useState<number>(50)
    const [skip, setSkip] = useState<number>(0)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [totalPages, setTotalPages] = useState<number>(0)
    const [allRecords, setAllRecords] = useState<number>(0)

    const handleTotalPages = async () => {
        const total = await getTotalConsultsByUser(user.username)
        setAllRecords(total)
        setTotalPages(Math.ceil(total / pageSize))
    }

    const recargar = () => {
        setIsLoading(true)
        dispatch(GetConsultsByUser(user.username, pageSize, skip))
        handleTotalPages()
    }

    useEffect(() => {
        if (!isEmpty(user)) {
            setIsLoading(true)
            dispatch(GetConsultsByUser(user.username, pageSize, skip))
            handleTotalPages()
        } else {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        setSkip((currentPage - 1) * pageSize)
        setIsLoading(true)
        dispatch(GetConsultsByUser(user.username, pageSize, (currentPage - 1) * pageSize))
        handleTotalPages()
    }, [currentPage])

    useEffect(() => {
        setIsLoading(false)
    }, [consults])

    return !isLoading && !isEmpty(consults) ? (
        <div className={styles.body}>
            <div className={styles.title}>Consultas atendidas por {user.username}</div>

            <div className="bg-white text-black p-5 flex justify-between">
                <p>Total de registros: {allRecords}</p>
                <button
                    className="bg-violetDark text-white px-3 font-montserrat text-sm rounded-md"
                    onClick={() => recargar()}
                >
                    Actualizar
                </button>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>Nro</th>
                        <th>Fecha</th>
                        <th>Tipo</th>
                        <th>Cliente</th>
                        <th>Consulta</th>
                        <th>Cartas</th>
                        <th>Respuesta</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {consults?.map((consult: any, index: number) => {
                        return (
                            <tr className={index % 2 === 0 ? styles.gris : styles.white}>
                                <td>{allRecords - skip - index}</td>
                                <td>
                                    <div>{new Date(consult.createdAt).toLocaleDateString()}</div>
                                    <div>{new Date(consult.createdAt).toLocaleTimeString()}</div>
                                </td>
                                <td>{consult.type}</td>
                                <td>{consult.clientName}</td>
                                <td>{resumen(consult.clientAnswer)}</td>
                                <td>
                                    {consult.cards.map((card: any, index: number) => {
                                        return index < 2 && <div className={styles.nowrap}>{card.name}</div>
                                    })}
                                </td>
                                <td>
                                    <p>{resumen(consult.response)}</p>
                                </td>
                                <td>
                                    <Link to={`/user/consults-view/${consult.objectId}`}>
                                        <img
                                            src={require('../../../assets/images/view.png')}
                                            alt=""
                                            className={styles.icon}
                                        />
                                    </Link>
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            <div className="mt-5 flex gap-2 justify-center">
                <button
                    className="bg-violetDark text-white px-3 font-montserrat text-sm rounded-md disabled:bg-slate-300 disabled:text-slate-400 py-2"
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Anterior
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                    <button
                        className={`py-2 px-3 border-2 border-violetDark text-sm text-white ${
                            i + 1 === currentPage ? 'bg-violetDark' : ''
                        }`}
                        key={i}
                        onClick={() => setCurrentPage(i + 1)}
                    >
                        {i + 1}
                    </button>
                ))}
                <button
                    className="bg-violetDark text-white px-3 font-montserrat text-sm rounded-md disabled:bg-slate-300 disabled:text-slate-400 py-2"
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Siguiente
                </button>
            </div>
        </div>
    ) : isLoading && isEmpty(consults) ? (
        <div className={styles.spinne_container}>
            <div className={styles.spinner}></div>
            <span className={styles.text_spinner}>Recuperando datos...</span>
        </div>
    ) : (
        <div className={styles.nofound}>No existen consultas guardadas</div>
    )
}

export default MyConsultas
