export const GET_ALL_CARDS = 'GET_ALL_CARDS'
export const SAVE_ALL_CARDS = 'SAVE_ALL_CALLS'
export const LOGIN = 'LOGIN'
export const ERROR = 'ERROR'
export const ERROR_LOGIN = 'ERROR_LOGIN'
export const RESET_ERROR = 'RESET_ERROR'
export const SET_USER = 'SET_USER'
export const GET_ALL_CONSULTS = 'GET_ALL_CONSULTS'
export const LOGOUT = 'LOGOUT'
export const SAVE_CONSULT = 'SAVE_CONSULT'
export const RESET_CONSULT = 'RESET_CONSULT'
export const SAVE_CLIENT = 'SAVE_CLIENT'
export const RESET_CLIENT = 'RESET_CLIENT'
export const GET_CLIENTS = 'GET_CLIENTS'
export const SET_CLIENT = 'SET_CLIENT'
export const SET_CONTROL = 'SET_CONTROL'
export const GET_USERS = 'GET_USERS'
export const GET_SESSIONS = 'GET_SESSIONS'
export const CLOSE_SESSION = 'CLOSE_SESSION'
