import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { DataType } from '../../../interfaces/utils'
import { AllCardsType } from '../../../data/all-cards'
import { Compatibilidad, CompatibilidadType, Zodiaco, ZodiacoType } from '../../../data/zodiaco'
import { copyResponse, fotoCartas, getZodiac, isEmpty, selectDiez, setMezcla } from '../../../utils/functions'
import { CheckUser, GetConsult, SaveAllCards } from '../../../store/actions'
import { ButtonNormal } from '../../../components/buttons/button-normal'
import { ShowCards } from './show-cards'
import PhotoCards from './photo-cards'
import { interpret } from './functions'
import { useNavigate } from 'react-router-dom'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import ContentPasteIcon from '@mui/icons-material/ContentPaste'

const Love = () => {
    const navigate = useNavigate()
    const dispatch: Dispatch<any> = useDispatch()
    const [data, setData] = useState<DataType>({
        consultante: {
            name: '',
            date: '',
            zodiac: '',
        },
        consultado: {
            name: '',
            date: '',
            zodiac: '',
        },
        consulta: '',
        tipo: '',
    })
    const [copia, setCopia]: any = useState(false)
    const { allCards, user, consult } = useSelector((state: any) => state)
    const [response, setResponse] = useState('')
    const [selectedCards, setSelectedCards] = useState<AllCardsType[]>([])
    const [showResponse, setShowResponse] = useState<boolean>(false)
    const [status, setStatus] = useState({
        isDone: false,
        mezcladas: false,
        repartidas: false,
        isConsulting: false,
        isInterpret: false,
    })
    const zodiaco: ZodiacoType[] = Zodiaco
    const compatibilidad: CompatibilidadType[] = Compatibilidad

    const nuevaPregunta = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            ...data,
            consultado: {
                name: '',
                date: '',
                zodiac: '',
            },
            consulta: '',
            tipo: '',
        })
        setShowResponse(false)
        setSelectedCards([])
    }

    const nuevoCliente = () => {
        setResponse('')
        setStatus({
            ...status,
            isDone: false,
            mezcladas: false,
            repartidas: false,
            isInterpret: false,
        })
        setData({
            consultante: {
                name: '',
                date: '',
                zodiac: '',
            },
            consultado: {
                name: '',
                date: '',
                zodiac: '',
            },
            consulta: '',
            tipo: '',
        })
        setShowResponse(false)
        setSelectedCards([])
    }

    // MANEJA LOS ESTADOS
    const handleStatus = (name: string, value: boolean): void => {
        setStatus({
            ...status,
            [name]: value,
        })
    }

    const handleZodiacConsultant = (date: string): void => {
        let zodiac = getZodiac(date)
        setData({
            ...data,
            consultante: {
                ...data.consultante,
                zodiac,
                date,
            },
        })
    }

    const handleZodiacThird = (date: string): void => {
        let zodiac = getZodiac(date)
        setData({
            ...data,
            consultado: {
                ...data.consultado,
                zodiac,
                date,
            },
        })
    }

    const handleInputConsultant = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'date' && parseInt(event.target.value.slice(0, 4)) > 1900) {
            handleZodiacConsultant(event.target.value)
        } else {
            setData({
                ...data,
                consultante: {
                    ...data.consultante,
                    [event.target.name]: event.target.value,
                },
            })
        }
    }

    const handleInputThird = (event: React.ChangeEvent<HTMLInputElement>): void => {
        if (event.target.name === 'date' && parseInt(event.target.value.slice(0, 4)) > 1900) {
            handleZodiacThird(event.target.value)
        } else {
            setData({
                ...data,
                consultado: {
                    ...data.consultado,
                    [event.target.name]: event.target.value,
                },
            })
        }
    }

    const handleConsult = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void => {
        setData({
            ...data,
            [event.target.name]: event.target.value,
        })
    }

    const mezcla = () => {
        const mezcladas = setMezcla(allCards)
        //console.log(mezcladas)
        dispatch(SaveAllCards(mezcladas))
        handleStatus('mezcladas', true)
    }

    const interpretar = () => {
        handleStatus('isConsulting', true)
        const info = interpret(selectedCards, data, user.username, 'amor')
        dispatch(GetConsult(info))
    }

    useEffect(() => {
        if (status.mezcladas && !status.repartidas) {
            setSelectedCards(selectDiez(allCards))
            handleStatus('repartidas', true)
        }
    }, [allCards, status])

    useEffect(() => {
        if (!isEmpty(consult)) {
            setStatus({
                ...status,
                isConsulting: false,
                isInterpret: true,
            })
            setResponse(consult.response)
            setShowResponse(true)
            //console.log(consult)
        }
    }, [consult])

    useEffect(() => {
        if (Object.entries(user).length === 0) {
            navigate('/')
        }
        setStatus({
            isConsulting: false,
            isInterpret: false,
            isDone: false,
            repartidas: false,
            mezcladas: false,
        })
        setShowResponse(false)
        setResponse('')
        setSelectedCards([])
    }, [])

    useEffect(() => {
        if (Object.entries(user).length === 0) {
            navigate('/')
        }
    }, [user])

    const copiar = () => {
        localStorage.setItem('client', JSON.stringify(data.consultante))
    }

    const pegar = () => {
        const cliente = localStorage.getItem('client')
        if (cliente) {
            const clienteObj = JSON.parse(cliente)
            console.log(clienteObj)
            setData({
                ...data,
                consultante: {
                    name: clienteObj.name,
                    date: clienteObj.date,
                    zodiac: clienteObj.zodiac,
                },
            })
        }
    }

    useEffect(() => {
        if (showResponse) {
            let signoConsultante = document.getElementById('consultante')
            if (signoConsultante && data.consultante.zodiac.length > 0) {
                let description = zodiaco.filter((signo) => signo.name === data.consultante.zodiac)
                signoConsultante.innerHTML = description[0].description
            }
            if (data.consultado.zodiac.length > 0) {
                let signoTercero = document.getElementById('tercero')
                if (signoTercero) {
                    let description = zodiaco.filter((signo) => signo.name === data.consultado.zodiac)
                    signoTercero.innerHTML = description[0].description
                }
                let compatib = document.getElementById('comp')
                if (compatib) {
                    let texto = compatibilidad.filter(
                        (elem) =>
                            elem.signos === data.consultante.zodiac + '-' + data.consultado.zodiac ||
                            elem.signos === data.consultado.zodiac + '-' + data.consultante.zodiac
                    )
                    compatib.innerHTML = texto[0].description
                }
            }
        }
    }, [showResponse, data, zodiaco, compatibilidad])

    // COPIA LA IMAGEN DE LAS CARTAS
    useEffect(() => {
        if (copia) {
            let element = document.getElementById('photo')
            if (element) {
                fotoCartas(element, data.consultante.name)
                setCopia(false)
            }
        }
    }, [copia])

    const toCompatibilidad = () => {
        localStorage.setItem('name_consultante', data.consultante.name)
        localStorage.setItem('date_consultante', data.consultante.date)
        localStorage.setItem('zodiac_consultante', data.consultante.zodiac)
        localStorage.setItem('name_consultado', data.consultado.name)
        localStorage.setItem('date_consultado', data.consultado.date)
        localStorage.setItem('zodiac_consultado', data.consultado.zodiac)
        navigate('/auto/compatibilidad')
    }

    return (
        <div className="w-full mini:min-h-screen mini:h-full xl:h-height-main bg-love bg-no-repeat bg-cover mini:px-2 md:px-4 relative mini:pb-10 xl:pb-0">
            <p className="mini:text-xl xl:text-4xl font-bold text-white italic w-full text-center py-5">
                Tirada del Amor
            </p>
            <div className="flex mini:flex-col xl:flex-row w-full">
                {/* left */}
                <div className="mini:w-full xl:w-1/2 bg-violetLight h-full rounded-lg mini:px-2 mini:py-5 md:p-5 flex flex-col gap-3 shadow-card-horizontal">
                    <div className="flex flex-col gap-1">
                        <div className="flex gap-3">
                            <input
                                type="radio"
                                name="tipo"
                                value={1}
                                id="one"
                                checked={data.tipo === '1'}
                                onChange={(e) => handleConsult(e)}
                            />
                            <label htmlFor="one">Amor (Sólo una persona)</label>
                        </div>
                        <div className="flex gap-3">
                            <input
                                type="radio"
                                name="tipo"
                                value={2}
                                id="two"
                                checked={data.tipo === '2'}
                                onChange={(e) => handleConsult(e)}
                            />
                            <label htmlFor="two">Vínculo (Dos personas)</label>
                        </div>
                        <div className="flex gap-3">
                            <input
                                type="radio"
                                name="tipo"
                                value={3}
                                id="three"
                                checked={data.tipo === '3'}
                                onChange={(e) => handleConsult(e)}
                            />
                            <label htmlFor="three">Tradicional (La tirada que se realizaba antes)</label>
                        </div>
                    </div>
                    <div className="flex mini:flex-col md:flex-row min gap-3">
                        <input
                            name="name"
                            type="text"
                            value={data.consultante.name}
                            onChange={(e) => handleInputConsultant(e)}
                            placeholder="Nombre del Consultante"
                            className="h-10 mini:w-full md:w-1/2 pl-3 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            autoFocus={true}
                        />
                        <div className="flex gap-3">
                            <input
                                type="date"
                                name="date"
                                value={data.consultante.date}
                                onChange={(e) => handleInputConsultant(e)}
                                placeholder="Nacimiento"
                                className="pl-3 pr-1lh-10 w-1/2 rounded-lg text-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            />
                            <input
                                type="text"
                                name="zodiac"
                                value={data.consultante.zodiac}
                                readOnly={true}
                                className="px-3 h-10 rounded-lg text-base text-gray-400 w-1/2 disabled:bg-white"
                                disabled={true}
                            />
                        </div>
                        <div className="flex items-center gap-1 text-white">
                            <button
                                className="bg-violetDark p-2 rounded-full hover:cursor-pointer disabled:bg-slate-400 disabled:text-slate-300 disabled:cursor-not-allowed"
                                onClick={() => copiar()}
                                title="Copiar"
                                disabled={data.consultante.name.length === 0 || data.consultante.zodiac.length === 0}
                            >
                                <ContentCopyIcon />
                            </button>
                            <button
                                className="bg-violetDark p-2 rounded-full hover:cursor-pointer"
                                onClick={() => pegar()}
                                title="Pegar"
                            >
                                <ContentPasteIcon />
                            </button>
                        </div>
                    </div>
                    {(data.tipo === '2' || data.tipo === '3') && (
                        <div className="flex mini:flex-col md:flex-row gap-3">
                            <input
                                name="name"
                                type="text"
                                value={data.consultado.name}
                                onChange={(e) => handleInputThird(e)}
                                placeholder="Nombre del Consultado"
                                className="h-10 mini:w-full md:w-1/2 pl-3 rounded-lg textr-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                            />
                            <div className="flex gap-3">
                                <input
                                    type="date"
                                    name="date"
                                    value={data.consultado.date}
                                    onChange={(e) => handleInputThird(e)}
                                    placeholder="Nacimiento"
                                    className="pl-3 pr-1 h-10 w-1/2 rounded-lg textr-base text-gray-400 focus-visible:outline-none focus-visible:border-violetDark focus-visible:border-2"
                                />
                                <input
                                    type="text"
                                    name="zodiac"
                                    value={data.consultado.zodiac}
                                    readOnly={true}
                                    className="px-3 h-10 rounded-lg textr-base text-gray-400 w-1/2 disabled:bg-white"
                                    disabled={true}
                                />
                            </div>
                        </div>
                    )}
                    <div>
                        <textarea
                            name="consulta"
                            value={data.consulta}
                            className="p-3 h-[40vh] rounded-lg textr-base text-gray-400 w-full"
                            placeholder="Aquí se pueden ingresar las inquietudes que tenga el consultante. Si no se ingresa nada, la tirada será general sobre los aspectos importantes que muestren las cartas."
                            onChange={(e) => handleConsult(e)}
                        />
                    </div>
                    <div className="flex gap-3 justify-center flex-wrap disabled:hidden">
                        <ButtonNormal
                            caption="Hecho"
                            onClick={() => handleStatus('isDone', true)}
                            disabled={
                                status.isDone ||
                                data.consultante.name.length === 0 ||
                                data.consultante.date.length === 0 ||
                                data.consultante.zodiac.length === 0 ||
                                data.tipo?.length === 0 ||
                                (data.tipo === '2' && data.consultante.name.length === 0)
                            }
                        />
                        <ButtonNormal
                            caption="Repartir"
                            onClick={() => mezcla()}
                            disabled={!status.isDone || status.repartidas}
                        />
                        <ButtonNormal
                            caption="Editar"
                            onClick={() => handleStatus('isDone', false)}
                            disabled={!status.isDone || status.repartidas}
                        />
                        <ButtonNormal
                            caption="Hacer foto de cartas"
                            onClick={() => setCopia(true)}
                            disabled={!status.repartidas || status.isConsulting}
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Interpretando' : 'Interpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.repartidas || status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption={status.isConsulting ? 'Reinterpretando' : 'Reinterpretar'}
                            onClick={() => interpretar()}
                            disabled={!status.isInterpret}
                            loading={status.isConsulting}
                        />
                        <ButtonNormal
                            caption="Ver Respuesta"
                            onClick={() => setShowResponse(true)}
                            disabled={response.length === 0}
                        />
                        <ButtonNormal
                            caption="Compatibilidad"
                            onClick={() => toCompatibilidad()}
                            disabled={data.tipo !== '2' || response.length === 0 || data.consultado.zodiac.length === 0}
                        />
                        <ButtonNormal
                            caption="Nueva Tirada"
                            onClick={() => nuevaPregunta()}
                            disabled={!status.isInterpret}
                        />
                        <ButtonNormal
                            caption="Nuevo Cliente"
                            onClick={() => nuevoCliente()}
                            disabled={!status.isInterpret}
                        />
                    </div>
                </div>
                {selectedCards.length > 0 ? (
                    <div className="mini:w-full xl:w-1/2 relative mini:py-10 xl:py-0">
                        <ShowCards selectedCards={selectedCards} />
                    </div>
                ) : null}
            </div>
            {selectedCards.length > 0 ? (
                <div
                    className={`absolute mini:w-fit 2xl:w-[60vw] mini:h-fit 2xl:h-[84vh] mini:top-[20vw] xl:top-[2vw] 2xl:top-[2%] mini:left-[10vw] xl:left-[20vw] 2xl:left-[15%] bg-tapiz ${
                        copia ? 'block' : 'hidden'
                    }`}
                    id="photo"
                >
                    <PhotoCards cards={selectedCards} />
                </div>
            ) : null}
            {showResponse ? (
                <div className="absolute w-[90vw] h-[90vh] top-[2%] left-[5%] bg-zinc-200 shadow-2xl flex flex-row gap-5 rounded-xl mini:px-2 mini:py-5 md:p-5">
                    <div className="mini:w-full md:w-2/3 flex flex-col gap-3">
                        <textarea
                            className="w-full h-full resize-none rounded-lg p-5"
                            value={response}
                            onChange={(e) => setResponse(e.target.value)}
                        />
                        <div className="flex flex-row flex-wrap justify-center gap-1">
                            <ButtonNormal caption="Volver" onClick={() => setShowResponse(false)} />
                            <ButtonNormal caption="Copiar Respuesta" onClick={() => copyResponse(response)} />
                            <ButtonNormal
                                caption="Compatibilidad"
                                onClick={() => toCompatibilidad()}
                                disabled={data.tipo !== '2' || data.consultado.zodiac.length === 0}
                            />
                            <ButtonNormal caption="Nueva Tirada" onClick={() => nuevaPregunta()} />
                            <ButtonNormal caption="Nuevo Cliente" onClick={() => nuevoCliente()} />
                        </div>
                    </div>
                    <div className="w-1/3 flex flex-col gap-3 mini:hidden xl:block">
                        <p className="font-semibold">Consultante: {data.consultante.zodiac}</p>
                        <div
                            className="w-full bg-zinc-50 p-5 rounded-lg text-sm overflow-y-auto"
                            id="consultante"
                        ></div>
                        {data.consultado.zodiac.length > 0 && (
                            <>
                                <p className="font-semibold">Consultado: {data.consultado.zodiac}</p>
                                <div
                                    className="w-full bg-zinc-50 p-5 rounded-lg text-sm overflow-y-auto"
                                    id="tercero"
                                ></div>
                            </>
                        )}
                        {data.consultado.zodiac.length > 0 && (
                            <>
                                <p className="font-semibold">
                                    Compatibilidades {data.consultante.zodiac + '-' + data.consultado.zodiac}
                                </p>
                                <div
                                    className="w.full bg-zinc-50 p-5 rounded-lg text-sm overflow-y-auto"
                                    id="comp"
                                ></div>
                            </>
                        )}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default Love
