import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { CloseSession, GetSessions, GetUsers } from '../../../store/actions'
import CancelIcon from '@mui/icons-material/Cancel'

const ConsultUsuarios = () => {
    const dispatch: Dispatch<any> = useDispatch()
    const { user, users, sessions } = useSelector((state: any) => state)

    useEffect(() => {
        dispatch(GetUsers())
        dispatch(GetSessions())
    }, [])

    const cerrarSesion = (sessionId: string) => {
        dispatch(CloseSession(sessionId))
    }

    return (
        <div className="pb-20 bg-darkGreen min-h-height-main px-28">
            <p className="w-full py-5 italic font-bold text-center text-white mini:text-xl xl:text-4xl font-pacifico drop-shadow-lg">
                Control de Usuarios
            </p>
            <div className="flex flex-col w-full px-10 py-10 bg-white rounded-lg">
                <div className="flex justify-end w-full">
                    <button className="px-5 py-1 text-white rounded-md bg-violetDark hover:scale-110">
                        Actualizar
                    </button>
                </div>
                {users?.map((element: any, index: number) => (
                    <div className="flex flex-col w-full" key={index}>
                        <p className="mt-10 text-lg font-semibold font-montserrat">{element.username}</p>
                        <table className="shadow-none">
                            <thead className="">
                                <th className="border-2 w-[11%]">FECHA Y HORA</th>
                                <th className="border-2 w-[15%]">SESION</th>
                                <th className="w-[25%] border-2">DISPOSITIVO</th>
                                <th className="border-2">TOKEN</th>
                                <th className="border-2">ACCION</th>
                            </thead>
                            <tbody className="">
                                {sessions?.reverse().map((session: any, index: number) =>
                                    session.user.objectId === element.objectId ? (
                                        <tr className="" key={index}>
                                            <td className="px-3 text-base text-center border-2 whitespace-nowrap">
                                                {new Date(session.createdAt).toLocaleString()}
                                            </td>
                                            <td className="px-3 text-base border-2">{session.objectId}</td>
                                            <td className="px-3 text-base border-2">
                                                {session.installationId || 'Indefinido'}
                                            </td>
                                            <td className="px-3 text-base border-2">{session.sessionToken}</td>
                                            <td className="flex justify-center px-3 text-base border-2">
                                                <button
                                                    className="px-5 py-1 text-white duration-300 bg-red-500 rounded-md hover:scale-110"
                                                    onClick={() => cerrarSesion(session.objectId)}
                                                >
                                                    <div className="flex items-center gap-2">
                                                        <CancelIcon fontSize="small" />
                                                        Cerrar
                                                    </div>
                                                </button>
                                            </td>
                                        </tr>
                                    ) : null
                                )}
                            </tbody>
                        </table>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default ConsultUsuarios
