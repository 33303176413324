import React, { useEffect, useState } from 'react'
import styles from './consults-view.module.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { ConsultsType } from '../../../interfaces/utils'
import { copyResponse, fotoCartas, isEmpty } from '../../../utils/functions'
import { AllCardsType } from '../../../data/all-cards'
import { GetAllCards } from '../../../store/actions'
import Card from '../../../components/card/card'
import { Dispatch } from 'redux'
import PhotoCards from '../../consultas/gral/photo-cards'

const ConsultsView: React.FC = (): JSX.Element => {
    const dispatch: Dispatch<any> = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const { consults, user, allCards } = useSelector((state: any) => state)
    const [consult, setConsult]: any = useState()
    const [response, setResponse] = useState<string>()
    const [copia, setCopia] = useState<boolean>(false)

    useEffect(() => {
        if (id) {
            let position = consults.findIndex((consult: ConsultsType) => consult.objectId === id)
            setConsult(consults[position])
            setResponse(consults[position].response)
        } else {
            navigate('/')
        }
        if (isEmpty(user)) {
            navigate('/')
        }
        if (isEmpty(allCards)) {
            dispatch(GetAllCards())
        }
    }, [consults, user, id, navigate, dispatch, allCards])

    const handleTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setResponse(event.target.value)
    }

    useEffect(() => {
        if (copia) {
            let element = document.getElementById('photo')
            if (element) {
                fotoCartas(element, consult?.clientName)
                setCopia(false)
            }
        }
    }, [copia])

    return (
        <div className={styles.body}>
            <div className={styles.title_container}>
                <button className={styles.button_back} onClick={() => navigate(-1)}>
                    <img src={require('../../../assets/images/arrow_left.png')} alt="" className={styles.back} />
                    Back
                </button>
                <span className={styles.title}>Detalle de la consulta</span>
            </div>
            <div className={styles.data_container}>
                <div className={styles.data_header}>
                    <span className={styles.data_title}>Código</span>
                    <span className={styles.data_title}>Tipo</span>
                    <span className={styles.data_title}>Fecha</span>
                    <span className={styles.data_title}>Hora</span>
                    <span className={styles.data_title}>Consultor</span>
                    <span className={styles.data_title}>Consultante</span>
                    <span className={styles.data_title}>Consulta</span>
                    <div className={styles.data + ' ' + styles.center}>{consult?.objectId}</div>
                    <div className={styles.data}>{consult?.type}</div>
                    <div className={styles.data + ' ' + styles.center}>
                        {new Date(consult?.createdAt).toLocaleDateString()}
                    </div>
                    <div className={styles.data + ' ' + styles.center}>
                        {new Date(consult?.createdAt).toLocaleTimeString()}
                    </div>
                    <div className={styles.data}>{consult?.user}</div>
                    <div className={styles.data}>{consult?.clientName}</div>
                    <div className={styles.data}>{consult?.clientAnswer}</div>
                </div>
                <div className="flex w-full gap-5 mt-5">
                    <div className="w-1/2">
                        <div className={styles.info_title}>Cartas</div>
                        <div className={styles.cards_container}>
                            {!isEmpty(allCards) &&
                                consult?.cards.map((card: AllCardsType, index: number) => (
                                    <Card card={card} key={index} />
                                ))}
                        </div>
                    </div>
                    <div className="w-1/2 pb-5">
                        <div className={styles.info_title}>Respuesta</div>
                        <div className={styles.response}>
                            <textarea
                                name="response"
                                value={response}
                                className={styles.textarea}
                                onChange={(e) => handleTextarea(e)}
                            ></textarea>
                        </div>
                    </div>
                </div>
                <div className="flex w-full gap-5 mt-10">
                    <div className="flex justify-center w-1/2">
                        <button
                            className="px-5 py-2 text-white rounded-md bg-violetDark"
                            onClick={() => setCopia(true)}
                        >
                            Copiar Cartas
                        </button>
                    </div>
                    <div className="flex justify-center w-1/2">
                        <button
                            className="px-5 py-2 text-white rounded-md bg-violetDark"
                            onClick={() => copyResponse(response!)}
                        >
                            Copiar Respuesta
                        </button>
                    </div>
                </div>
            </div>
            {consult && (
                <div
                    className={`absolute w-fit h-fit top-[16vh] mini:left-[3vw] xl:left-[20vw] bg-tapiz ${
                        copia ? 'block' : 'hidden'
                    }`}
                    id="photo"
                >
                    <PhotoCards cards={consult.cards} />
                </div>
            )}
        </div>
    )
}

export default ConsultsView
