import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch } from 'redux'
import { GetClients, SetClient } from '../../store/actions'
import DayOne from './day-one'
import DayTwo from './day-two'
import DayThree from './day-three'
import DayFour from './day-four'
import DayFive from './day-five'
import DaySix from './day-six'
import DaySeven from './day-seven'
import DayEight from './day-eight'
import DayNine from './day-nine'
import DayTen from './day-ten'

const FollowUp = ({ setStatus }: { setStatus: (value: number) => void }) => {
    const dispatch: Dispatch<any> = useDispatch()
    const client = useSelector((state: any) => state.client)
    const clients = useSelector((state: any) => state.clients)
    const [data, setData] = useState({
        client: '',
        obs: '',
    })
    const [loading, setLoading] = useState(false)
    const [select, setSelect] = useState<number>(0)
    const [active, setActive] = useState<boolean>(false)

    useEffect(() => {
        if (!client.name) {
            setLoading(true)
            dispatch(GetClients())
        }
    }, [])

    useEffect(() => {
        if (clients.length > 0) {
            setLoading(false)
            setActive(true)
        }
    }, [clients])

    const handleSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setData({
            ...data,
            client: event.target.value,
        })
        setSelect(0)
        dispatch(SetClient(clients.filter((client: any) => client.objectId === event.target.value)[0]))
    }

    const handleTextarea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setData({
            ...data,
            obs: event.target.value,
        })
    }

    return (
        <div className="bg-purple-300 rounded-lg shadow-md mini:px-0 mini:py-10 maxi:p-10 flex flex-col gap-5 mini:mx-5 maxi:mx-20 mini:h-full maxi:h-[80vh]">
            <div className="flex mini:flex-col maxi:flex-row w-full h-full p-5">
                <div className="flex flex-col gap-2 mini: w-full maxi:w-1/2">
                    <label htmlFor="name" className="flex flex-col text-gray-600 text-lg">
                        Cliente
                        <div className="flex gap-3 w-full items-center">
                            <select
                                name="name"
                                value={data.client}
                                className="h-10 w-72 rounded-lg pl-3 text-lg text-gray-400"
                                onChange={(e) => handleSelect(e)}
                                disabled={!active}
                            >
                                <option value="" className="" disabled>
                                    Seleccione el cliente
                                </option>
                                {clients?.map((client: any, index: number) => (
                                    <option value={client.objectId} key={index}>
                                        {client.name}
                                    </option>
                                ))}
                            </select>
                            {loading ? <div className="spinner"></div> : null}
                        </div>
                    </label>
                    <label htmlFor="phone" className="flex flex-col text-gray-600 text-lg">
                        Teléfono
                        <div className="h-10 rounded-lg bg-white pl-3 flex items-center">
                            <p className="text-gray-400 text-lg">{client?.phone}</p>
                        </div>
                    </label>
                    <label htmlFor="date" className="flex flex-col text-gray-600 text-lg">
                        Fecha de Nacimiento
                        <div className="h-10 rounded-lg bg-white pl-3 flex items-center">
                            <p className="text-gray-400 text-lg">
                                {client.birthday
                                    ? client?.birthday.slice(8) +
                                      '/' +
                                      client?.birthday.slice(5, 7) +
                                      '/' +
                                      client?.birthday.slice(0, 4)
                                    : null}
                            </p>
                        </div>
                    </label>
                    <label htmlFor="email" className="flex flex-col text-gray-600 text-lg">
                        Email
                        <div className="h-10 rounded-lg bg-white pl-3 flex items-center">
                            <p className="text-gray-400 text-lg">{client?.email}</p>
                        </div>
                    </label>
                    <label htmlFor="obs" className="flex flex-col text-gray-600 text-lg">
                        Observaciones
                        <textarea
                            name="obs"
                            value={client?.obs}
                            rows={7}
                            className="text-gray-400 text-lg p-3 rounded-lg h-full"
                            onChange={(e) => handleTextarea(e)}
                        ></textarea>
                    </label>
                </div>
                {select === 0 && data.client !== '' && (
                    <div className="mini:w-full maxi:w-1/2 h-full flex flex-wrap gap-5 maxi:p-5 mini:px-2 mini:py-10 justify-center">
                        {/* BUTTON DIA 1 */}
                        <div
                            className={`w-40 h-40 text-white flex flex-col justify-center items-center font-semibold text-lg opacity-70 hover:opacity-100 hover:cursor-pointer rounded-lg ${
                                client.control[0].video && client.control[0].audio && client.control[0].indications
                                    ? 'bg-green-600'
                                    : 'bg-red-500'
                            }`}
                            onClick={() => setSelect(1)}
                        >
                            <div className="w-full h-2/3 flex flex-col justify-center items-center">
                                <p>DIA 1</p>
                                <p className="font-normal text-base font-pacifico italic">Logros</p>
                            </div>
                            <div className="w-full h-1/3 flex items-center justify-around px-2">
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[0].video ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[0].audio ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[0].indications ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[0].extra ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[0].razones ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                            </div>
                        </div>
                        {/* BUTTON DIA 2 */}
                        <div
                            className={`w-40 h-40 text-white flex flex-col justify-center items-center font-semibold text-lg opacity-70 hover:opacity-100 hover:cursor-pointer rounded-lg ${
                                client.control[1].video && client.control[1].audio && client.control[1].indications
                                    ? 'bg-green-600'
                                    : 'bg-red-500'
                            }`}
                            onClick={() => setSelect(2)}
                        >
                            <div className="w-full h-2/3 flex flex-col justify-center items-center">
                                <p>DIA 2</p>
                                <p className="font-normal text-base font-pacifico italic">Fracasos</p>
                            </div>
                            <div className="w-full h-1/3 flex items-center justify-around px-2">
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[1].video ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[1].audio ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[1].indications ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[1].extra ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[1].razones ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                            </div>
                        </div>
                        {/* BUTTON DIA 3 */}
                        <div
                            className={`w-40 h-40 text-white flex flex-col justify-center items-center font-semibold text-lg opacity-70 hover:opacity-100 hover:cursor-pointer rounded-lg ${
                                client.control[2].video && client.control[2].audio && client.control[2].indications
                                    ? 'bg-green-600'
                                    : 'bg-red-500'
                            }`}
                            onClick={() => setSelect(3)}
                        >
                            <div className="w-full h-2/3 flex flex-col justify-center items-center">
                                <p>DIA 3</p>
                                <p className="font-normal text-base font-pacifico italic">Aprendizaje</p>
                            </div>
                            <div className="w-full h-1/3 flex items-center justify-around px-2">
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[2].video ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[2].audio ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[2].indications ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[2].extra ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[2].razones ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                            </div>
                        </div>
                        {/* BUTTON DIA 4 */}
                        <div
                            className={`w-40 h-40 text-white flex flex-col justify-center items-center font-semibold text-lg opacity-70 hover:opacity-100 hover:cursor-pointer rounded-lg ${
                                client.control[3].video && client.control[3].audio && client.control[3].indications
                                    ? 'bg-green-600'
                                    : 'bg-red-500'
                            }`}
                            onClick={() => setSelect(4)}
                        >
                            <div className="w-full h-2/3 flex flex-col justify-center items-center">
                                <p>DIA 4</p>
                                <p className="font-normal text-base font-pacifico italic">Paradigmas</p>
                            </div>
                            <div className="w-full h-1/3 flex items-center justify-around px-2">
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[3].video ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[3].audio ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[3].indications ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[3].extra ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[3].razones ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                            </div>
                        </div>
                        {/* BUTTON DIA 5 */}
                        <div
                            className={`w-40 h-40 text-white flex flex-col justify-center items-center font-semibold text-lg opacity-70 hover:opacity-100 hover:cursor-pointer rounded-lg ${
                                client.control[4].video && client.control[4].audio && client.control[4].indications
                                    ? 'bg-green-600'
                                    : 'bg-red-500'
                            }`}
                            onClick={() => setSelect(5)}
                        >
                            <div className="w-full h-2/3 flex flex-col justify-center items-center">
                                <p>DIA 5</p>
                                <p className="font-normal text-base font-pacifico italic">Valores</p>
                            </div>
                            <div className="w-full h-1/3 flex items-center justify-around px-2">
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[4].video ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[4].audio ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[4].indications ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[4].extra ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[4].razones ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                            </div>
                        </div>
                        {/* BUTTON DIA 6 */}
                        <div
                            className={`w-40 h-40 text-white flex flex-col justify-center items-center font-semibold text-lg opacity-70 hover:opacity-100 hover:cursor-pointer rounded-lg ${
                                client.control[5].video && client.control[5].audio && client.control[5].indications
                                    ? 'bg-green-600'
                                    : 'bg-red-500'
                            }`}
                            onClick={() => setSelect(6)}
                        >
                            <div className="w-full h-2/3 flex flex-col justify-center items-center">
                                <p>DIA 6</p>
                                <p className="font-normal text-base font-pacifico italic">Roles</p>
                            </div>
                            <div className="w-full h-1/3 flex items-center justify-around px-2">
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[5].video ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[5].audio ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[5].indications ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[5].extra ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[5].razones ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                            </div>
                        </div>
                        {/* BUTTON DIA 7 */}
                        <div
                            className={`w-40 h-40 text-white flex flex-col justify-center items-center font-semibold text-lg opacity-70 hover:opacity-100 hover:cursor-pointer rounded-lg ${
                                client.control[6].video && client.control[6].audio && client.control[6].indications
                                    ? 'bg-green-600'
                                    : 'bg-red-500'
                            }`}
                            onClick={() => setSelect(7)}
                        >
                            <div className="w-full h-2/3 flex flex-col justify-center items-center">
                                <p>DIA 7</p>
                                <p className="font-normal text-base font-pacifico italic">Rol clave</p>
                            </div>
                            <div className="w-full h-1/3 flex items-center justify-around px-2">
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[6].video ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[6].audio ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[6].indications ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[6].extra ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[6].razones ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                            </div>
                        </div>
                        {/* BUTTON DIA 8 */}
                        <div
                            className={`w-40 h-40 text-white flex flex-col justify-center items-center font-semibold text-lg opacity-70 hover:opacity-100 hover:cursor-pointer rounded-lg ${
                                client.control[7].video && client.control[7].audio && client.control[7].indications
                                    ? 'bg-green-600'
                                    : 'bg-red-500'
                            }`}
                            onClick={() => setSelect(8)}
                        >
                            <div className="w-full h-2/3 flex flex-col justify-center items-center">
                                <p>DIA 8</p>
                                <p className="font-normal text-base font-pacifico italic">Metas</p>
                            </div>
                            <div className="w-full h-1/3 flex items-center justify-around px-2">
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[7].video ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[7].audio ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[7].indications ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[7].extra ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[7].razones ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                            </div>
                        </div>
                        {/* BUTTON DIA 9 */}
                        <div
                            className={`w-40 h-40 text-white flex flex-col justify-center items-center font-semibold text-lg opacity-70 hover:opacity-100 hover:cursor-pointer rounded-lg ${
                                client.control[8].video && client.control[8].audio && client.control[8].indications
                                    ? 'bg-green-600'
                                    : 'bg-red-500'
                            }`}
                            onClick={() => setSelect(9)}
                        >
                            <div className="w-full h-2/3 flex flex-col justify-center items-center">
                                <p>DIA 9</p>
                                <p className="font-normal text-base font-pacifico italic">10 metas</p>
                            </div>
                            <div className="w-full h-1/3 flex items-center justify-around px-2">
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[8].video ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[8].audio ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[8].indications ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[8].extra ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[8].razones ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                            </div>
                        </div>
                        {/* BUTTON DIA 10 */}
                        <div
                            className={`w-40 h-40 text-white flex flex-col justify-center items-center font-semibold text-lg opacity-70 hover:opacity-100 hover:cursor-pointer rounded-lg ${
                                client.control[9].video && client.control[9].audio && client.control[9].indications
                                    ? 'bg-green-600'
                                    : 'bg-red-500'
                            }`}
                            onClick={() => setSelect(10)}
                        >
                            <div className="w-full h-2/3 flex flex-col justify-center items-center">
                                <p>DIA 10</p>
                                <p className="font-normal text-base font-pacifico italic">Plan de acción</p>
                            </div>
                            <div className="w-full h-1/3 flex items-center justify-around px-2">
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[9].video ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[9].audio ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[9].indications ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[9].extra ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                                <div
                                    className={`h-5 w-5 border-4 border-black rounded-full ${
                                        client.control[9].razones ? 'bg-orange-500' : 'bg-black'
                                    }`}
                                ></div>
                            </div>
                        </div>
                    </div>
                )}
                {select === 1 && <DayOne />}
                {select === 2 && <DayTwo />}
                {select === 3 && <DayThree />}
                {select === 4 && <DayFour />}
                {select === 5 && <DayFive />}
                {select === 6 && <DaySix />}
                {select === 7 && <DaySeven />}
                {select === 8 && <DayEight />}
                {select === 9 && <DayNine />}
                {select === 10 && <DayTen />}
            </div>
            {select === 0 && (
                <div className="flex justify-end gap-4">
                    <button
                        className="h-10 rounded-lg bg-slate-400 px-5 hover:scale-110 active:scale-95 text-white duration-200"
                        onClick={() => setStatus(0)}
                    >
                        Cancelar
                    </button>
                    <button className="h-10 rounded-lg bg-purple-500 px-5 hover:scale-110 active:scale-95 text-white duration-200">
                        Guardar
                    </button>
                </div>
            )}
            {select !== 0 && (
                <div className="flex justify-end">
                    <button
                        className="h-10 rounded-lg bg-purple-500 px-10 hover:scale-110 active:scale-95 text-white duration-200"
                        onClick={() => setSelect(0)}
                    >
                        Salir
                    </button>
                </div>
            )}
        </div>
    )
}

export default FollowUp
